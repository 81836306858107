import logo from "./logo.svg";
import polo from "./polo.png";
import R2024 from "./2024.png";
import biweekly from "./biweekly.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <h1>Single Make Tuner Series</h1>
          <h4>Who's it for: If you like tuning, you hate the stock tune, you're in UTC 0 to +4 time zone. 8pm Tuesday nights.</h4>
          <h4>All races are run in private lobbies. Send a friend request over PSN to PSN:ReA_P3r_84 & PSN:toonies55 & PSN:DC_Gaming-92</h4>
          <h2>2024 seasons</h2>
          <br />
          <img alt="" src={R2024} />
          <br />

        </p>
        <p>
        <h3>Past winners</h3>
        Clio RS cup: Jan to Jun 2023  (Last Tuesday of the month)  winners: Reaper, Nitro, Dutchmann <br/>
Polo GTI cup: Jul to Dec 2023 (Last Tuesday of the month)  winners: DC, Kennels, Toonies
        </p>
        <a
          className="App-link"
          href="https://discord.gg/XXhhDFwe"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join the discord to get the regulations and calendar and see how to enter
        </a>
        <a
          className="App-link"
          href="https://www.youtube.com/@singlemaketunerseries"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube
        </a>
      </header>
    </div>
  );
}

export default App;
